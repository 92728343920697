import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
// import { Auth0Provider } from "@auth0/auth0-react";

import rootReducer from "./redux/reducers/rootReducer";
import LanguageChecker from "./LanguageChecker";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

ReactDOM.render(
  // <Auth0Provider
  //   domain="cute-bear-shop.jp.auth0.com"
  //   clientId="tXL4cTLVFyZQJVXD5N6YpPBN8OPRF7r3"
  //   redirectUri={window.location.origin}
  // >
  <Provider store={store}>
    <LanguageChecker />
  </Provider>,
  //</Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
