import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchProducts } from "./redux/actions/productActions";
import getProducts from "./data/getProducts.js";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
// import PageViewTracker from "./components/PageViewTracker";
// import AuthStateTracker from "./components/AuthStateTracker";
import "./i18n";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const Contact = lazy(() => import("./pages/other/Contact"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Profile = lazy(() => import("./pages/other/Profile"));
const CheckoutComplete = lazy(() => import("./pages/other/CheckoutComplete"));
const BlogPost = lazy(() => import("./pages/blog/Blog"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const products = getProducts(t);
    dispatch(fetchProducts(products));
  }, [t, dispatch]);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />
                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopGridStandard}
                />
                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route path={process.env.PUBLIC_URL + "/faq"} component={FAQ} />
                <Route
                  path={process.env.PUBLIC_URL + "/blog"}
                  component={BlogPost}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout-complete"}
                  component={CheckoutComplete}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={Profile}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route exact component={NotFound} />
              </Switch>

              {/* Replace with chatbot. */}
              {/* <div className="text-3xl font-bold underline text-red-900">
                Hello world!
              </div> */}
            </Suspense>
          </ScrollToTop>
          {/* <PageViewTracker /> */}
          {/* <AuthStateTracker /> */}
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

export default App;
