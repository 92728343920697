import { SET_CURRENCY } from "../actions/currencyActions";
import getLanguage from "../../helpers/getLanguage";

const currencyData = {
  USD: {
    currencySymbol: "$",
    currencyName: "USD",
    currencyRate: 1,
    decimalDigit: 2,
  },
  TWD: {
    currencySymbol: "NT$",
    currencyName: "TWD",
    currencyRate: 31.616198,
    decimalDigit: 0,
  },
  JPY: {
    currencySymbol: "¥",
    currencyName: "JPY",
    currencyRate: 144.36797,
    decimalDigit: 0,
  },
  KRW: {
    currencySymbol: "₩",
    currencyName: "KRW",
    currencyRate: 1418.949373,
    decimalDigit: 0,
  },
};

const langCurrencyMapping = {
  en: "USD",
  zh_TW: "TWD",
  ja: "JPY",
  ko: "KRW",
};

const initState = {
  ...currencyData[langCurrencyMapping[getLanguage()]],
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const { currencyName } = action.payload;
    const currency = currencyData[currencyName];
    if (currency) {
      return {
        ...currency,
      };
    }
    return state;
  }

  return state;
};

export default currencyReducer;
