export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const UPDATE_SPONSORED_PRODUCTS_SUCCESS =
  "UPDATE_SPONSORED_PRODUCTS_SUCCESS";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

// fetch products
export const fetchProducts = (products) => {
  return (dispatch) => {
    dispatch(fetchProductsSuccess(products));
  };
};

const updateSponsoredProductsSuccess = (sponsoredProducts) => ({
  type: UPDATE_SPONSORED_PRODUCTS_SUCCESS,
  payload: sponsoredProducts,
});

export const updateSponsoredProducts = (sponsoredProducts) => {
  return (dispatch) => {
    dispatch(updateSponsoredProductsSuccess(sponsoredProducts));
  };
};
