import React, { useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

import App from "./App";
import { getSearchParamsLang, supportLanguages } from "./helpers/getLanguage";

const LanguageChecker = () => {
  const lang = getSearchParamsLang();
  // const { isLoading } = useAuth0();

  useEffect(() => {
    // Do not perform URL pleacement before Auth0 has done token exchange.
    // Ref: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
    //
    // if (isLoading) return;

    if (!supportLanguages.includes(lang)) {
      // const urlParams = new URLSearchParams(window.location.search);
      // urlParams.append("lang", "en");
      // window.location.search = urlParams;
      const url = new URL(window.location.href);
      url.searchParams.set("lang", "en");
      window.location.replace(url.toString());
    }
  }, [lang]);

  if (!supportLanguages.includes(lang)) {
    return null;
  }
  return <App />;
};

export default LanguageChecker;
