import productsJson from "./products.json";

// TODO: Remove below code after G vendor demo stable.
// const getProducts = (t) => [
//   {
//     id: '1',
//     name: t("product.SummerKissLipstick", "Summer Kiss Lipstick"),
//     price: 25,
//     discount: undefined,
//     new: true,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.lip", "lip")],
//     variation: [{
//       color: t("color.red", "red"),
//       image: "/assets/imgDemo/beauty/SummerKissLipstick.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/SummerKissLipstick.png"],
//     shortDescription: t("product.SummerKissLipstick.description", `Our Cute Bear Lipstick is enriched with hydrating butter, creating a creamy and long-lasting lipstick that glides easily, keeping your lips the perfect shade 24/7.`),
//     fullDescription: t("product.SummerKissLipstick.description", `Our Cute Bear Lipstick is enriched with hydrating butter, creating a creamy and long-lasting lipstick that glides easily, keeping your lips the perfect shade 24/7.`),
//   }, {
//     id: '2',
//     name: t("product.CuteBearSkinSerum", "Cute Bear Skin Serum"),
//     price: 34,
//     discount: undefined,
//     new: true,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.skincare", "skincare"), t("tag.lip", "lip")],
//     variation: [{
//       color: t("color.brown", "brown"),
//       image: "/assets/imgDemo/beauty/CuteBearSkinSerum.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/CuteBearSkinSerum.png"],
//     shortDescription: t("product.CuteBearSkinSerum.description", `Cute Bear Skin Serum is a proven serum effective for all skin tones.  This is a must-have product to keep your skin beautiful night and day, working with your natural daily rhythm to protect you all day long.`),
//     fullDescription: t("product.CuteBearSkinSerum.description", `Cute Bear Skin Serum is a proven serum effective for all skin tones.  This is a must-have product to keep your skin beautiful night and day, working with your natural daily rhythm to protect you all day long.`),
//   }, {
//     id: '3',
//     name: t("product.LovelyEyeshadowQuad", "Lovely Eyeshadow Quad"),
//     price: 85,
//     discount: undefined,
//     new: true,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.eye", "eye")],
//     variation: [{
//       color: t("color.pink", "pink"),
//       image: "/assets/imgDemo/beauty/LovelyEyeshadowQuad.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.red", "red"),
//       image: "/assets/imgDemo/beauty/LovelyEyeshadowQuad.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.purple", "purple"),
//       image: "/assets/imgDemo/beauty/LovelyEyeshadowQuad.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/LovelyEyeshadowQuad.png"],
//     shortDescription: t("product.LovelyEyeshadowQuad.description", `Express your real self using our Lovely Eyeshadow Quad.  Its rich colors can be combined in infinite ways with color pairings carefully selected by our beauty experts.  Designed to enhance your natural eye color and match your skin tone beautifully.`),
//     fullDescription: t("product.LovelyEyeshadowQuad.description", `Express your real self using our Lovely Eyeshadow Quad.  Its rich colors can be combined in infinite ways with color pairings carefully selected by our beauty experts.  Designed to enhance your natural eye color and match your skin tone beautifully.`),
//   }, {
//     id: '4',
//     name: t("product.LongWearFoundation", "Long-Wear Foundation"),
//     price: 45,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.face", "face")],
//     variation: [{
//       color: t("color.nature", "nature"),
//       image: "/assets/imgDemo/beauty/LongWearFoundation.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/LongWearFoundation.png"],
//     shortDescription: t("product.LongWearFoundation.description", `The #1 best selling foundation in Cute Bear.  You can confidently use this foundation to look flawless every day.  Oil-free with all natural ingredients, it will keep your skin oil under tight control 24/7. This is a liquid foundation with a wide range of shades that won’t look gray on darker skin tones.`),
//     fullDescription: t("product.LongWearFoundation.description", `The #1 best selling foundation in Cute Bear.  You can confidently use this foundation to look flawless every day.  Oil-free with all natural ingredients, it will keep your skin oil under tight control 24/7. This is a liquid foundation with a wide range of shades that won’t look gray on darker skin tones.`),
//   }, {
//     id: '5',
//     name: t("product.FaceMoisturizer", "Face Moisturizer"),
//     price: 39,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.skincare", "skincare"), t("tag.face", "face")],
//     variation: [{
//       color: t("color.pink", "pink"),
//       image: "/assets/imgDemo/beauty/FaceMoisturizer.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/FaceMoisturizer.png"],
//     shortDescription: t("product.FaceMoisturizer.description", `Our rich and creamy Face Moisturizer is your best tool to tackle the dry weather and keep your face feeling firm, radiant, and full of moisture.  Designed to restore and rejuvenate your face, smoothing wrinkles and lines while you get your well deserved rest.`),
//     fullDescription: t("product.FaceMoisturizer.description", `Our rich and creamy Face Moisturizer is your best tool to tackle the dry weather and keep your face feeling firm, radiant, and full of moisture.  Designed to restore and rejuvenate your face, smoothing wrinkles and lines while you get your well deserved rest.`),
//   }, {
//     id: '6',
//     name: t("product.BlackMascara", "Black Mascara"),
//     price: 27,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.eye", "eye")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/beauty/BlackMascara.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/BlackMascara.png"],
//     shortDescription: t("product.BlackMascara.description", `Our long-wear black mascara can highlight your best feature, and is easy to use with any eye shape or size.  Over 90% of our customers saw instantly thicker lashes after using our mascara.`),
//     fullDescription: t("product.BlackMascara.description", `Our long-wear black mascara can highlight your best feature, and is easy to use with any eye shape or size.  Over 90% of our customers saw instantly thicker lashes after using our mascara.`),
//   }, {
//     id: '7',
//     name: t("product.CCCream", "CC Cream"),
//     price: 44,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.face", "face")],
//     variation: [{
//       color: t("color.nature", "nature"),
//       image: "/assets/imgDemo/beauty/CCCream.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/CCCream.png"],
//     shortDescription: t("product.CCCream.description", `Our CC cream is a deep color foundation strengthened with SPF 50+ to block out the harsh sunshine.  Use this foundation to protect and smooth your skin with a natural finish.`),
//     fullDescription: t("product.CCCream.description", `Our CC cream is a deep color foundation strengthened with SPF 50+ to block out the harsh sunshine.  Use this foundation to protect and smooth your skin with a natural finish.`),
//   }, {
//     id: '8',
//     name: t("product.PetalLipstick", "Petal Lipstick"),
//     price: 20,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.lip", "lip")],
//     variation: [{
//       color: t("color.pink", "pink"),
//       image: "/assets/imgDemo/beauty/PetalLipstick.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/PetalLipstick.png"],
//     shortDescription: t("product.PetalLipstick.description", `The new product from Cute Bear can make your lips petal-soft while delivering up to 18 hours of long lasting color, keeping your lips soft and hydrated 24/7.`),
//     fullDescription: t("product.PetalLipstick.description", `The new product from Cute Bear can make your lips petal-soft while delivering up to 18 hours of long lasting color, keeping your lips soft and hydrated 24/7.`),
//   }, {
//     id: '9',
//     name: t("product.SettingPowderQuad", "Setting Powder Quad"),
//     price: 46,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.face", "face")],
//     variation: [{
//       color: t("color.nature", "nature"),
//       image: "/assets/imgDemo/beauty/SettingPowderQuad.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/SettingPowderQuad.png"],
//     shortDescription: t("product.SettingPowderQuad.description", `This setting powder quad is designed to match a wide range of skin tones, helping you tackle every scene with the best combination every time. Its natural finish will not leave an oily residue, helping you blur and smooth all skin imperfections quickly and easily.`),
//     fullDescription: t("product.SettingPowderQuad.description", `This setting powder quad is designed to match a wide range of skin tones, helping you tackle every scene with the best combination every time. Its natural finish will not leave an oily residue, helping you blur and smooth all skin imperfections quickly and easily.`),
//   }, {
//     id: '10',
//     name: t("product.LightingPalette", "Lighting Palette"),
//     price: 68,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.beauty", "beauty")],
//     tag: [t("tag.makeup", "makeup"), t("tag.face", "face")],
//     variation: [{
//       color: t("color.brown", "brown"),
//       image: "/assets/imgDemo/beauty/LightingPalette.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.nature", "nature"),
//       image: "/assets/imgDemo/beauty/LightingPalette.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/beauty/LightingPalette.png"],
//     shortDescription: t("product.LightingPalette.description", `Our Lighting Palette features our four best selling shades of lighting powder, a soft finishing powder that keeps your skin flawless and keeps you confident all day long.`),
//     fullDescription: t("product.LightingPalette.description", `Our Lighting Palette features our four best selling shades of lighting powder, a soft finishing powder that keeps your skin flawless and keeps you confident all day long.`),
//   }, {
//     id: '11',
//     name: t("product.CoffeeGrinder", "Coffee Grinder"),
//     price: 24.95,
//     discount: 15,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.kitchen", "kitchen")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/CoffeeGrinder.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/CoffeeGrinder.png"],
//     shortDescription: t("product.CoffeeGrinder.description", `Start your day with an energy boost with this Cute Bear coffee grinder.  Enjoy freshly ground coffee every morning in just a few minutes.  Comes with a 3-year manufacturer limited warranty.`),
//     fullDescription: t("product.CoffeeGrinder.description", `Start your day with an energy boost with this Cute Bear coffee grinder.  Enjoy freshly ground coffee every morning in just a few minutes.  Comes with a 3-year manufacturer limited warranty.`),
//   }, {
//     id: '12',
//     name: t("product.ClothesSteamer", "Clothes Steamer"),
//     price: 26.99,
//     discount: 15,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.green", "green"),
//       image: "/assets/imgDemo/electronics/ClothesSteamer.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/electronics/ClothesSteamer.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/ClothesSteamer.png"],
//     shortDescription: t("product.ClothesSteamer.description", `Portable clothes steamer to keep your clothes wrinkle free while traveling.   Contains a large 360ml water tank and lasts for up to 30 minutes of continuous ironing.`),
//     fullDescription: t("product.ClothesSteamer.description", `Portable clothes steamer to keep your clothes wrinkle free while traveling.   Contains a large 360ml water tank and lasts for up to 30 minutes of continuous ironing.`),
//   }, {
//     id: '13',
//     name: t("product.FoodMixer", "Food Mixer"),
//     price: 199.99,
//     discount: 15,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.kitchen", "kitchen")],
//     variation: [{
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/electronics/FoodMixer.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.silver", "silver"),
//       image: "/assets/imgDemo/electronics/FoodMixer.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/FoodMixer.png"],
//     shortDescription: t("product.FoodMixer.description", `Our food mixer has a mixing bowl made of 316 stainless steel, keeping it strong while easy to clean.  It can handle 40 cupcakes or mixing 20 eggs, making it an ideal gift for people who enjoy baking.`),
//     fullDescription: t("product.FoodMixer.description", `Our food mixer has a mixing bowl made of 316 stainless steel, keeping it strong while easy to clean.  It can handle 40 cupcakes or mixing 20 eggs, making it an ideal gift for people who enjoy baking.`),
//   }, {
//     id: '14',
//     name: t("product.WashingMachine", "Washing Machine"),
//     price: 254.97,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/electronics/WashingMachine.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/WashingMachine.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/WashingMachine.png"],
//     shortDescription: t("product.WashingMachine.description", `With an attractive, easy to use design, this washing machine is perfect for people who have limited space.  It has multiple wash settings, allowing you to always find the wash that best fits your clothing type.`),
//     fullDescription: t("product.WashingMachine.description", `With an attractive, easy to use design, this washing machine is perfect for people who have limited space.  It has multiple wash settings, allowing you to always find the wash that best fits your clothing type.`),
//   }, {
//     id: '15',
//     name: t("product.AirPurifier", "Air Purifier"),
//     price: 99.99,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/electronics/AirPurifier.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/AirPurifier.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/AirPurifier.png"],
//     shortDescription: t("product.AirPurifier.description", `Our air purifier is a lifesaver for people or pets with allergies, removing 99.9% of allergens and dust in your room.  Our #1 best seller in electronics, it has multiple filter options and operates silently without disturbing those around you.`),
//     fullDescription: t("product.AirPurifier.description", `Our air purifier is a lifesaver for people or pets with allergies, removing 99.9% of allergens and dust in your room.  Our #1 best seller in electronics, it has multiple filter options and operates silently without disturbing those around you.`),
//   }, {
//     id: '16',
//     name: t("product.RobotVacuum", "Robot Vacuum"),
//     price: 215.58,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/electronics/RobotVacuum.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/RobotVacuum.png"],
//     shortDescription: t("product.RobotVacuum.description", `Our robot vacuum can be connected and controlled using your phone.  With our easy to use app you can start or stop the machine, schedule your cleaning session, and the long runtime up to 120 minutes ensures your entire house gets cleaned in one go.  Comes with three interchangeable brushes to handle any type of flooring.`),
//     fullDescription: t("product.RobotVacuum.description", `Our robot vacuum can be connected and controlled using your phone.  With our easy to use app you can start or stop the machine, schedule your cleaning session, and the long runtime up to 120 minutes ensures your entire house gets cleaned in one go.  Comes with three interchangeable brushes to handle any type of flooring.`),
//   }, {
//     id: '17',
//     name: t("product.LEDSmartTV", "LED Smart TV"),
//     price: 179.99,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.livingRoom", "livingRoom")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/LEDSmartTV.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/LEDSmartTV.png"],
//     shortDescription: t("product.LEDSmartTV.description", `Our LED Smart TV has Chromecast built-in, allowing you to enjoy tons of online streaming applications instantly without any need to purchase a separate device.  Our industry-leading 90-day return policy covers you with peace of mind.`),
//     fullDescription: t("product.LEDSmartTV.description", `Our LED Smart TV has Chromecast built-in, allowing you to enjoy tons of online streaming applications instantly without any need to purchase a separate device.  Our industry-leading 90-day return policy covers you with peace of mind.`),
//   }, {
//     id: '18',
//     name: t("product.HairDryer", "Hair Dryer"),
//     price: 36.54,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/HairDryer.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/HairDryer.png"],
//     shortDescription: t("product.HairDryer.description", `Portable hair dryer that you can bring during your travels.  This dryer creates a strong airflow to dry your hair quickly without damaging it, and overheat protection automatically shuts off the dryer, preventing accidents and allowing you to use the hair dryer safely.`),
//     fullDescription: t("product.HairDryer.description", `Portable hair dryer that you can bring during your travels.  This dryer creates a strong airflow to dry your hair quickly without damaging it, and overheat protection automatically shuts off the dryer, preventing accidents and allowing you to use the hair dryer safely.`),
//   }, {
//     id: '19',
//     name: t("product.BluetoothHeadphones", "Bluetooth Headphones"),
//     price: 22.58,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/BluetoothHeadphones.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/BluetoothHeadphones.png"],
//     shortDescription: t("product.BluetoothHeadphones.description", `With our class-leading energy saving bluetooth headphones, enjoy up to 32 hours of playtime with noise cancelling to drown out the outside.  At only 330grams, it is light on your head and comfortable to wear all day long.  Our smart connect feature enables the headphones to connect to any device for easy access to your music anytime.`),
//     fullDescription: t("product.BluetoothHeadphones.description", `With our class-leading energy saving bluetooth headphones, enjoy up to 32 hours of playtime with noise cancelling to drown out the outside.  At only 330grams, it is light on your head and comfortable to wear all day long.  Our smart connect feature enables the headphones to connect to any device for easy access to your music anytime.`),
//   }, {
//     id: '20',
//     name: t("product.Treadmill", "Treadmill"),
//     price: 319.99,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.electronics", "electronics")],
//     tag: [t("tag.general", "general")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/electronics/Treadmill.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.silver", "silver"),
//       image: "/assets/imgDemo/electronics/Treadmill.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/electronics/Treadmill.png"],
//     shortDescription: t("product.Treadmill.description", `Our treadmill has a smart detection response, adjusting the treadmill speed to match your running instantly.  The fold-up design makes this treadmill a perfect fit for those with limited space, and the 300lb weight capacity ensures any person can use it comfortably.  Comes with a 3-year limited warranty.`),
//     fullDescription: t("product.Treadmill.description", `Our treadmill has a smart detection response, adjusting the treadmill speed to match your running instantly.  The fold-up design makes this treadmill a perfect fit for those with limited space, and the 300lb weight capacity ensures any person can use it comfortably.  Comes with a 3-year limited warranty.`),
//   }, {
//     id: '21',
//     name: t("product.BrownCoat", "Brown Coat"),
//     price: 340,
//     discount: undefined,
//     new: false,
//     saleCount: 100,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.outer", "outer"), t("tag.formal", "formal")],
//     variation: [{
//       color: t("color.brown", "brown"),
//       image: "/assets/imgDemo/apparel/BrownCoat.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/BrownCoat.png"],
//     shortDescription: t("product.BrownCoat.description", `The Cute Bear brown coat is made of 50/50 polyester and wool blend, keeping you warm while being resistant to getting wet.  This coat is purposely oversized for a trendy look that ages beautifully.  Dry clean only.`),
//     fullDescription: t("product.BrownCoat.description", `The Cute Bear brown coat is made of 50/50 polyester and wool blend, keeping you warm while being resistant to getting wet.  This coat is purposely oversized for a trendy look that ages beautifully.  Dry clean only.`),
//   }, {
//     id: '22',
//     name: t("product.LibertyBag", "Liberty Bag"),
//     price: 7.63,
//     discount: undefined,
//     new: false,
//     saleCount: 100,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.accessories", "accessories"), t("tag.casual", "casual")],
//     variation: [{
//       color: t("color.blue", "blue"),
//       image: "/assets/imgDemo/apparel/LibertyBag.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/LibertyBag.png"],
//     shortDescription: t("product.LibertyBag.description", `These liberty bags are simple bags made of nylon and can help you stand out from the crowd.  It is a must-have item for working out at the gym, or hanging out outdoors with your friends.  The blue liberty bag is our #1 best-selling color.`),
//     fullDescription: t("product.LibertyBag.description", `These liberty bags are simple bags made of nylon and can help you stand out from the crowd.  It is a must-have item for working out at the gym, or hanging out outdoors with your friends.  The blue liberty bag is our #1 best-selling color.`),
//   }, {
//     id: '23',
//     name: t("product.BaseballCap", "Baseball Cap"),
//     price: 7.77,
//     discount: undefined,
//     new: false,
//     saleCount: 100,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.accessories", "accessories"), t("tag.casual", "casual")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/apparel/BaseballCap.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/BaseballCap.png"],
//     shortDescription: t("product.BaseballCap.description", `This classic baseball cap is made of 100% cotton,  keeping it breathable, comfortable and one of the best choices for everyday wear.  Adjustable headband makes it an easy pick for everyone to wear.`),
//     fullDescription: t("product.BaseballCap.description", `This classic baseball cap is made of 100% cotton,  keeping it breathable, comfortable and one of the best choices for everyday wear.  Adjustable headband makes it an easy pick for everyone to wear.`),
//   }, {
//     id: '24',
//     name: t("product.BusinessCasualShirtForMen", "Business Casual Shirt for Men"),
//     price: 18.99,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.shirt", "shirt"), t("tag.top", "top"), t("tag.formal", "formal")],
//     variation: [{
//       color: t("color.black", "black"),
//       image: "/assets/imgDemo/apparel/BusinessCasualShirtForMen.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.blue", "blue"),
//       image: "/assets/imgDemo/apparel/BusinessCasualShirtForMen.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/apparel/BusinessCasualShirtForMen.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/BusinessCasualShirtForMen.png"],
//     shortDescription: t("product.BusinessCasualShirtForMen.description", `Cute Bear presents you our latest product line focused around business casual shirts for men.  We designed these suits to be perfect whether you are meeting in an office, outside playing golf, or just hanging out at a local party.  The slim cut and raised sleeves create a fitted look while keeping you in all-day comfort.`),
//     fullDescription: t("product.BusinessCasualShirtForMen.description", `Cute Bear presents you our latest product line focused around business casual shirts for men.  We designed these suits to be perfect whether you are meeting in an office, outside playing golf, or just hanging out at a local party.  The slim cut and raised sleeves create a fitted look while keeping you in all-day comfort.`),
//   }, {
//     id: '25',
//     name: t("product.LeatherJacket", "Leather Jacket"),
//     price: 69,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.outer", "outer"), t("tag.top", "top")],
//     variation: [{
//       color: t("color.brown", "brown"),
//       image: "/assets/imgDemo/apparel/LeatherJacket.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/LeatherJacket.png"],
//     shortDescription: t("product.LeatherJacket.description", `Show off the rock&roll in your soul with this brown leather jacket.  It has a classic silhouette that looks good and feels even better.  Vegan leather is friendly to the local animals, letting you rock out guilt-free all day and night.`),
//     fullDescription: t("product.LeatherJacket.description", `Show off the rock&roll in your soul with this brown leather jacket.  It has a classic silhouette that looks good and feels even better.  Vegan leather is friendly to the local animals, letting you rock out guilt-free all day and night.`),
//   }, {
//     id: '26',
//     name: t("product.FlowerSilkTie", "Flower Silk Tie"),
//     price: 8.83,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.accessories", "accessories"), t("tag.formal", "formal")],
//     variation: [{
//       color: t("color.red", "red"),
//       image: "/assets/imgDemo/apparel/FlowerSilkTie.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.blue", "blue"),
//       image: "/assets/imgDemo/apparel/FlowerSilkTie.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/FlowerSilkTie.png"],
//     shortDescription: t("product.FlowerSilkTie.description", `With this easy-wear tie, you can suit up for any formal occasion in mere seconds, making your life easy without sacrificing your style.  Made of polyester silk, making it flexible and easy to clean.`),
//     fullDescription: t("product.FlowerSilkTie.description", `With this easy-wear tie, you can suit up for any formal occasion in mere seconds, making your life easy without sacrificing your style.  Made of polyester silk, making it flexible and easy to clean.`),
//   }, {
//     id: '27',
//     name: t("product.ShinyHighHeels", "Shiny High Heels"),
//     price: 34.41,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.shoes", "shoes"), t("tag.formal", "formal")],
//     variation: [{
//       color: t("color.white", "white"),
//       image: "/assets/imgDemo/apparel/ShinyHighHeels.png",
//       size: [{"name":"x","stock":20}],
//     }, {
//       color: t("color.gold", "gold"),
//       image: "/assets/imgDemo/apparel/ShinyHighHeels.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/ShinyHighHeels.png"],
//     shortDescription: t("product.ShinyHighHeels.description", `Cute Bear presents our latest shiny high heels with 1.25" platform height. It has a latex padded insole to keep your feet coddled, comfortable to wear all night and show off those legs.  Includes sizes 5-8.`),
//     fullDescription: t("product.ShinyHighHeels.description", `Cute Bear presents our latest shiny high heels with 1.25" platform height. It has a latex padded insole to keep your feet coddled, comfortable to wear all night and show off those legs.  Includes sizes 5-8.`),
//   }, {
//     id: '28',
//     name: t("product.GreenDress", "Green Dress"),
//     price: 60,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.onePiece", "onePiece"), t("tag.casual", "casual")],
//     variation: [{
//       color: t("color.green", "green"),
//       image: "/assets/imgDemo/apparel/GreenDress.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/GreenDress.png"],
//     shortDescription: t("product.GreenDress.description", `One of our best selling dresses.  This Green dress has a slimming fit and is highlighted with the iconic beltline.  Suitable for both professional and casual settings.`),
//     fullDescription: t("product.GreenDress.description", `One of our best selling dresses.  This Green dress has a slimming fit and is highlighted with the iconic beltline.  Suitable for both professional and casual settings.`),
//   }, {
//     id: '29',
//     name: t("product.StripedSocks", "Striped Socks"),
//     price: 15.47,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.accessories", "accessories")],
//     variation: [{
//       color: t("color.blue", "blue"),
//       image: "/assets/imgDemo/apparel/StripedSocks.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/StripedSocks.png"],
//     shortDescription: t("product.StripedSocks.description", `Combining our iconic Cute Bear colors with a classic stripe pattern, these socks will have you skipping to the store!  No matter whether you are going to school or going to work, these stripe socks are ready for any occasion.`),
//     fullDescription: t("product.StripedSocks.description", `Combining our iconic Cute Bear colors with a classic stripe pattern, these socks will have you skipping to the store!  No matter whether you are going to school or going to work, these stripe socks are ready for any occasion.`),
//   }, {
//     id: '30',
//     name: t("product.Suit", "Suit"),
//     price: 89.97,
//     discount: undefined,
//     new: false,
//     saleCount: 0,
//     category: [t("category.apparel", "apparel")],
//     tag: [t("tag.top", "top"), t("tag.formal", "formal")],
//     variation: [{
//       color: t("color.brown", "brown"),
//       image: "/assets/imgDemo/apparel/Suit.png",
//       size: [{"name":"x","stock":20}],
//     }],
//     image: ["/assets/imgDemo/apparel/Suit.png"],
//     shortDescription: t("product.Suit.description", `Made with natural colors, this suit can fit in any situation.  Regular fit keeps you comfortable in the working environment, and the Polyester blend is easy to clean, breathable and won’t wrinkle easily.`),
//     fullDescription: t("product.Suit.description", `Made with natural colors, this suit can fit in any situation.  Regular fit keeps you comfortable in the working environment, and the Polyester blend is easy to clean, breathable and won’t wrinkle easily.`),
//   },
//   {
//     "id": "31",
//     "key": "suv",
//     "name": t("product.suv", "2023 X1 SPORTS"),
//     "price": 50095,
//     "new": false,
//     "category": [t("category.car", "car")],
//     "tag": [],
//     "variation": [
//       {
//         "color": "white",
//         "image": "/assets/imgDemo/car/suv_made_in_the_year_2027.png",
//         "size": [
//           {
//             "name": "X1",
//             "stock": 20
//           }
//         ]
//       }
//     ],
//     "image": ["/assets/imgDemo/car/suv_made_in_the_year_2027.png"],
//     "shortDescription": t("product.suv.shortDescription", "The 2023 Cute Bear X1 Sports Activity Vehicle® has new features, including fingertip control with the BMW Curved Display, a range of exterior paint finishes, and Sport Seats with SensaTec upholstery for added comfort and support."),
//     "fullDescription": t("product.suv.fullDescription", "The versatile Cute Bear 2023 X1 Sports Activity Vehicle® packs a lot of power and personality in a compact body – including these features, new for 2023: (1) Fingertip control. The expansive BMW Curved Display, appearing on the X1 for the first time, includes touch-focused interaction design, (2) Bold and bright: Discover the biggest range of exterior paint finishes ever available for the X1 – and the stylish upgrades of the xLine Package, (3) Supportive seats: Add the available Sport Seats with SensaTec upholstery for a new level of driving comfort and support.")
//   },
//   {
//     "id": "32",
//     "key": "paris_travel",
//     "name": t("product.paris_travel", "Paris: Eiffel Tower Guided Tour with Direct Access & Summit"),
//     "price": 999,
//     "new": false,
//     "category": [t("category.travel", "travel")],
//     "tag": [],
//     "variation": [
//       {
//         "color": "colorful",
//         "image": "/assets/imgDemo/travel/iStock-1364380431.jpg",
//         "size": [
//           {
//             "name": "paris_tour",
//             "stock": 10
//           }
//         ]
//       }
//     ],
//     "image": ["/assets/imgDemo/travel/iStock-1364380431.jpg"],
//     "shortDescription": t("product.paris_travel.shortDescription", "Get direct access to the Eiffel Tower and enjoy the dramatic views of Paris from the summit with a short guided tour. Learn about its history and construction and get tips on visiting other famous landmarks in the city. Optional summit visit and champagne bar available."),
//     "fullDescription": t("product.paris_travel.fullDescription", "Make the most of your time in Paris with direct access to the Eiffel Tower. Embark on a short guided tour and soak up the dramatic views of the French capital from the Eiffel Tower's summit. Admire Paris from above at the Eiffel Tower and learn about this iconic landmark on a guided tour. Enhance your experience with an optional visit to the summit and take in the dramatic views over the City of Lights. Meet your guide close to the base of the Eiffel Tower, and enjoy direct access to ascend to the second floor. Here, admire views of Montmartre, the Louvre, Arc de Triomphe, Notre Dame and much more. From there, ascend to the very top of the Eiffel Tower. Enjoy beautiful, sweeping views across the city before peeking inside Gustave Eiffel's apartment at the top of the tower. If you wish, you can treat yourself by purchasing a drink from the champagne bar at the top of the tower. Listen to your guide as he tells you stories of the tower's construction and history and how it was almost torn down and sold for scraps. Your guide will also share tips and advice on the best ways to visit the city's major monuments.")
//   },
//   {
//     "id": "33",
//     "key": "apartment",
//     "name": t("product.apartment", "Fifth Avenue Condo Home w| Central Park Views + 794Sqft Terrace"),
//     "price": 3000000,
//     "new": false,
//     "category": [t("category.apartment", "apartment")],
//     "tag": [],
//     "variation": [
//       {
//         "color": "colorful",
//         "image": "/assets/imgDemo/apartment/iStock-1373329869.jpg",
//         "size": [
//           {
//             "name": "manhattan_apartment",
//             "stock": 1
//           }
//         ]
//       }
//     ],
//     "image": ["/assets/imgDemo/apartment/iStock-1373329869.jpg"],
//     "shortDescription": t('product.apartment.shortDescription', 'This luxurious high-floor home with stunning views of Central Park features a grand living room with fireplace, glass pocket doors to the dining room, three spacious bedrooms with en-suite bathrooms, state-of-the-art kitchen, custom laundry room, terrace with natural gas grill, and building amenities including a fitness center, playroom, and 24/7 concierge. The meticulously renovated pre-war condominium is located on Fifth Avenue, offering easy access to Central Park and nearby attractions.'),
//     "fullDescription": t('product.apartment.fullDescription', `Brimming with unparalleled Central Park views this Exquisite high-floor home has it all. Starting from the private elevator landing that opens to a perfectly appointed entry gallery leading into the grand living room with 9-foot ceilings, and a beautiful gas fireplace . Relax and take in the breathtaking views of Central Park. Glass pocket doors open into the grand dining room which continues the theme of full Central Park views from the double windows with overhead interior lighting.

//       The spacious primary bedroom features 3 windows with interior lighting facing west into Central Park. It includes a seating area/office and two custom closets. The room also features a dressing room, a walk-in closet that comes with custom shoe cabinets, ample storage space, and custom drawers and includes a 5’ custom safe with a large amount of storage space. Luxuriously appointed Bianco Dolomiti windowed Marble primary bathrooms to feature radiant floor heat, a 5’6"-wide custom millwork vanities, Dornbracht fixtures, walk-in showers, separate bathtub custom light fixtures, and Robern medicine cabinets with integrated outlets, and large linen closet.

//       The West wing of the apartment includes two spacious bright bedrooms. One bedroom faces south and has a large en-suite windowed tile bathroom with a linen closet. The other corner bedroom has double windows facing East and South, with 3 ample closets and an en-suite tile bathroom. The West wing also has a long bright hallway with a custom-designed desk area, which features ample cabinets for storage and two wine refrigerators.

//       State-of-the-art kitchens feature xx-large custom windows Dornbracht fixtures, two Miele dishwashers, two refrigerators, freezers, a six-burner gas stove with griddle, double oven, a Miele microwave, Bertazzoni appliances, Italian custom cabinets, Caesarstone countertops and Insinkerator, and a cozy breakfast area.

//       A Custom designed Laundry Room with a commercial-size washer and dryer, folding table, sink, lots of storage, and two closets.

//       TERRACE approx 794 sqft, with three access ways; from an internal spiral staircase, from a private back stairwell, or via the back Elevator. Custom-fitted stairwell floor from the 16th floor to the 17th floor that accesses the terrace, outdoor space with natural gas grill, two gas burners, a refrigerator, ice machine dishwasher, and covered bar area.

//       The property also features: Beautifully restored plaster coffered ceilings and moldings, solid oak wood floors, solid core doors with four hinges, custom millwork for heating and air conditioning enclosures, recessed LED lighting, brand new thermo-pane casement windows, and individual thermostats in every room. Hard-wired automatic shades, as well as battery-operated blackout shades, and incorporated sound system throughout.

//       Building amenities; Residents lounge, state-of-the-art 2,600 Square Foot Fitness Center including a Pilates room, additional laundry facilities, bicycle room, a playroom, and cold storage.

//       Location, 1212 Fifth Avenue is a meticulously and comprehensively renovated pre-war condominium on Fifth Avenue; the condominium prides itself on being the first classic prewar building in New York City to receive LEED Gold certification. A full-service building with 24/7 concierge attended lobby and live-in resident manager. Central Park at your door, the Vanderbilt Gate, the Conservatory Gardens, Museum miles, Mt Sinai Hospital, New York Academy of medicine, great public transportation, and parking garages.

//       There is a ground-level windowed storage room (which requires an extra fee) that is thermostatically controlled.`)
//     }
// ];

// NOTE: Because all products stored in local storage, below is to prevent over storage limit.
const getProducts = (t) => productsJson.slice(0, 3000);

export default getProducts;
