export const supportLanguages = ["en", "zh_TW", "ja", "ko"];

export const getSearchParamsLang = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("lang");
};

const getLanguage = () => {
  const urlLang = getSearchParamsLang();
  return supportLanguages.includes(urlLang) ? urlLang : "en";
};

export default getLanguage;
